<template>
  <div class="addApi">
    <a-modal
      title="添加接口"
      :visible="visible"
      :mask-closable="false"
      :confirm-loading="loading"
      ok-text="确认"
      cancel-text="取消"
      :width="600"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="content">
        <a-form :label-col="{span:6 }" :wrapper-col="{span: 18}">
          <a-form-item label="名称">
            <a-input id="name" v-model="data.name" placeholder="名称" />
          </a-form-item>
          <a-form-item label="类别">
            <a-select v-model="data.groupId" placeholder="请选择类别">
              <a-select-option v-for="(item,index) in groupList" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="路径">
            <a-input id="addr" v-model="data.addr" placeholder="请输入路径" />
          </a-form-item>
          <a-form-item label="方法">
            <a-radio-group v-model="data.method">
              <a-radio-button value="get">
                get
              </a-radio-button>
              <a-radio-button value="post">
                post
              </a-radio-button>
              <a-radio-button value="put">
                put
              </a-radio-button>
              <a-radio-button value="delete">
                delete
              </a-radio-button>
            </a-radio-group>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import myApi from '../../../../api/api'
export default {
  props: {
    groupList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      data: {
        name: '',
        groupId: undefined,
        method: '',
        addr: ''
      }
    }
  },
  methods: {
    showAddModal() {
      this.visible = true
      this.data = {
        name: '',
        groupId: undefined,
        method: '',
        addr: ''
      }
    },
    handleCancel() {
      this.visible = false
    },
    handleOk() {
      this.loading = true
      myApi.add(this.data).then(res => {
        if (res.status === 200) {
          this.$message.success(res.msg)
          this.visible = false
          this.$emit('on-ok')
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => { this.loading = false })
    }
  }
}
</script>
<style scoped>
.content {
  padding-left: 40px;
  padding-right: 40px;
  box-sizing:  border-box;
}
</style>
