<template>
  <div class="myapi">
    <!--左侧选择框 -->
    <div class="left-box">
      <div class="left-top-box">
        <a-button icon="plus-circle" type="primary" ghost @click="handleAddGroupView">添加分组</a-button>
      </div>
      <div class="fenge" />
      <a-spin :spinning="loading.group">
        <div class="left-content-box">
          <div :class="{'left-content-item': true, 'left-content-item-active': '' === selectGroupId}" @click="handleSelectGroup({id: '', name:'全部分类'})">
            <a-icon type="build" style="margin-right:10px" />
            全部分类
          </div>
          <div v-for="(item, index) in groupList" :key="index" :class="{'left-content-item': true, 'left-content-item-active': item.id === selectGroupId}" @click="handleSelectGroup(item)">
            <a-icon type="build" style="margin-right:10px" />
            {{ item.name }}
            <span class="left-item-setting">
              <a-icon type="edit" style="margin-right:10px" @click.stop="handleUpdateGroupView(item)" />
              <a-icon type="delete" @click.stop="handleDeleteGrouopView(item)" />
            </span>
          </div>
        </div>
      </a-spin>
    </div>
    <!-- 右侧表格 -->
    <div class="right-box">
      <div class="right-top-box">
        <span>{{ selectGroupName }} ({{ apiList.length }})</span>
        <span style="float:right"><a-button type="primary" icon="plus-circle" ghost @click="handleApiAddView">添加接口</a-button></span>
      </div>
      <div class="fenge" />
      <div class="right-content-box">
        <a-spin :spinning="loading.api">
          <a-table :row-key="(record,index)=>{return index}" :data-source="apiList" :pagination="false">
            <a-table-column key="index" title="序号">
              <template slot-scope="text,record,index">
                {{ index + 1 }}
              </template>
            </a-table-column>
            <a-table-column key="name" width="220px" title="名称">
              <template slot-scope="text,record">
                {{ record.name }}
              </template>
            </a-table-column>
            <a-table-column key="method" title="路径">
              <template slot-scope="text,record">
                <a-tag style="width:50px;text-align: center;line-height: 22px;margin-right:10px" :color="getColorByMethod(record.method)">{{ record.method }}</a-tag> {{ record.addr }}
              </template>
            </a-table-column>
            <a-table-column key="groupName" title="类别名称">
              <template slot-scope="text,record">
                {{ record.groupName }}
              </template>
            </a-table-column>
            <a-table-column key="setting" title="操作" width="200px">
              <template slot-scope="text,record">
                <a-button style="margin-right:10px" ghost type="primary" @click="handleApiEditView(record)">编辑</a-button>
                <a-button ghost type="danger" @click="handleApiDeleteView(record)">删除</a-button>
              </template>
            </a-table-column>
          </a-table>
        </a-spin>
      </div>
    </div>
    <group-add-modal ref="groupAddModal" @on-ok="fetchGroupData" />
    <group-update-modal ref="groupUpdateModal" @on-ok="fetchGroupData" />
    <api-add-modal ref="apiAddModal" :group-list="groupList" @on-ok="fetchApiData" />
    <api-update-modal ref="apiUpdateModal" :group-list="groupList" @on-ok="fetchApiData" />
  </div>
</template>

<script>
import myApi from '../../../api/api'
import ApiAddModal from './component/ApiAddModal.vue'
import ApiUpdateModal from './component/ApiUpdateModal.vue'
import GroupAddModal from './component/GroupAddModal.vue'
import GroupUpdateModal from './component/GroupUpdateModal.vue'
export default {
  components: { GroupAddModal, GroupUpdateModal, ApiAddModal, ApiUpdateModal },
  data() {
    return {
      groupList: [],
      selectGroupId: '',
      selectGroupName: '全部分类',
      apiList: [],
      loading: {
        group: false,
        api: false
      }
    }
  },
  created() {
    this.fetchGroupData()
    this.fetchApiData()
  },
  methods: {
    fetchGroupData() {
      this.loading.group = true
      myApi.groupList().then(res => {
        this.groupList = res.data
      }).finally(_ => { this.loading.group = false })
    },
    fetchApiData() {
      this.loading.api = true
      myApi.list({ groupId: this.selectGroupId }).then(res => {
        this.apiList = res.data
      }).finally(_ => { this.loading.api = false })
    },
    handleAddGroupView() {
      this.$refs.groupAddModal.showAddModal()
    },
    handleSelectGroup(item) {
      this.selectGroupId = item.id
      this.selectGroupName = item.name
      this.fetchApiData()
    },
    handleUpdateGroupView(item) {
      this.$refs.groupUpdateModal.showEditModal(item)
    },
    handleDeleteGrouopView(item) {
      const that = this
      this.$confirm({
        title: `确定删除[${item.name}]分组吗?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          myApi.groupDel(item.id).then(res => {
            that.$message.success(res.msg)
            that.fetchGroupData()
          })
        },
        onCancel() {
        }
      })
    },
    getColorByMethod(method) {
      switch (method) {
        case 'get': return 'blue'
        case 'post': return 'green'
        case 'put': return 'yellow'
        case 'delete': return 'red'
      }
    },
    handleApiAddView() {
      this.$refs.apiAddModal.showAddModal()
    },
    handleApiEditView(item) {
      this.$refs.apiUpdateModal.showModal(item)
    },
    handleApiDeleteView(item) {
      const that = this
      this.$confirm({
        title: `确定删除[${item.name}]接口吗?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          myApi.del(item.id).then(res => {
            that.$message.success(res.msg)
            that.fetchApiData()
          })
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
<style scoped>
.myapi{
  padding: 30px;
  display: flex;
}
.left-box {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #FAFAFA;
  box-shadow: 0 20px 27px rgba(0,0,0,.2);
  width: 400px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.right-box {
  width: 100%;
  margin-left: 20px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #FAFAFA;
  box-shadow: 0 20px 27px rgba(0,0,0,.2);
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.right-top-box {
  height: 32px;
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
}
.fenge {
  width: 100%;
  height: 1px;
  background-color: #e4e4e4;
  margin-top: 20px;
  margin-bottom: 20px;
}
.left-content-item {
  margin-top: 5px;
  margin-bottom: 5px;
  line-height: 54px;
  height: 54px;
  font-size: 14px;
  cursor: pointer;
  padding-left: 20px;
  border-radius: 10px;
  transition: background-color .3s,color .3s,box-shadow .3s;
}
.left-item-setting {
  display: none;
  float: right;
  margin-right: 20px;
}
.left-content-item:hover .left-item-setting {
   display: none;
  float: right;
  margin-right: 20px;
  display: unset;
}
.left-content-item:hover {
  background-color: #e8f4ff;
  color: #1890ff;
  box-shadow: 0 20px 27px rgba(0,0,0,.05);
}

.left-content-item-active {
  background-color: #e8f4ff;
  color: #1890ff;
  box-shadow: 0 20px 27px rgba(0,0,0,.05);
}
::v-deep .ant-table-thead > tr > th {
  background-color: #e8f4ff !important;
  color: #1890ff;
}
::v-deep .ant-table-tbody > tr > td {
  background-color: #ffffff !important;
}
.right-content-box {
  height: calc(100% - 100px);
  overflow-y: auto;
}
.right-content-box::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.right-content-box::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow   : inset 0 0 2px rgba(0, 0, 0, 0.2);
  background   : #40a9ff;
}
.right-content-box::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background   : #ededed;
}
</style>
