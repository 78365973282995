<template>
  <div class="editUser">
    <a-modal
      title="编辑分组"
      :visible="visible"
      :mask-closable="false"
      :confirm-loading="loading"
      ok-text="确认"
      cancel-text="取消"
      :width="400"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="content">
        <a-form :label-col="{span:6 }" :wrapper-col="{span: 18}">
          <a-form-item label="名称">
            <a-input id="name" v-model="editData.name" placeholder="名称" />
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>

<script>
import myApi from '../../../../api/api'
export default {
  props: {
  },
  data() {
    return {
      visible: false,
      loading: false,
      editData: {
        id: '',
        name: ''
      }
    }
  },
  methods: {
    showEditModal(data) {
      this.visible = true
      this.editData = JSON.parse(JSON.stringify(data))
    },
    handleCancel() {
      this.visible = false
    },
    handleOk() {
      this.loading = true
      myApi.groupEdit(this.editData).then(res => {
        if (res.status === 200) {
          this.$message.success(res.msg)
          this.visible = false
          this.$emit('on-ok')
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => { this.loading = false })
    }
  }
}
</script>
<style scoped>
.content {
  padding-left: 20px;
  padding-right: 20px;
  box-sizing:  border-box;
}
</style>
